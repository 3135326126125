<template>
  <section class="filter-wrap base-shadow">
    <el-form ref="form" :model="tableFilters" label-width="auto">
      <el-row :gutter="20">
        <el-col :sm="24" :md="12" :lg="8">
          <el-form-item label="月份">
            <el-row :gutter="10">
              <el-col :span="18">
                <el-date-picker
                  style="width: 100%"
                  v-model="tableFilters.yearMonth"
                  type="month"
                  value-format="yyyy-MM"
                  placeholder="选择月"
                >
                </el-date-picker>
              </el-col>
              <el-col :span="5"
                ><el-button type="text" @click="checkCurrentMonth"
                  >显示本月</el-button
                ></el-col
              >
            </el-row>
          </el-form-item>
        </el-col>
        <el-col :sm="24" :md="12" :lg="8">
          <el-form-item label="负责人(多选)">
            <el-row :gutter="10">
              <el-col :span="18">
                <BasicSelect
                  multiple
                  v-model="tableFilters.examineeId"
                  :allOptions="_ownerOptions"
                />
              </el-col>
              <el-col :span="5"
                ><el-button type="text" @click="onCheckSelf"
                  >只看自己</el-button
                ></el-col
              >
            </el-row>
          </el-form-item>
        </el-col>
        <el-col :sm="24" :md="12" :lg="8">
          <el-form-item label="部门(多选)">
            <el-row :gutter="10">
              <el-col :span="18">
                <el-cascader
                  style="width: 100%"
                  :options="_departmnetTreeOptions"
                  v-model="tableFilters.departmentIds"
                  collapse-tags
                  :props="{
                    multiple: true,
                    emitPath: false,
                    label: 'name',
                    value: 'id',
                    children: 'subs',
                  }"
                  clearable
                  @change="handleDepartmentChange"
                ></el-cascader>
              </el-col>
              <el-col :span="5"
                ><el-button type="text" @click="onCheckSelfDepartment"
                  >只看部门</el-button
                ></el-col
              >
            </el-row>
          </el-form-item>
        </el-col>
        <template v-if="showMoreFilter">
          <el-col :sm="24" :md="12" :lg="8">
            <el-form-item label="创建日期">
              <el-date-picker
                v-model="tableFilters.createdAt"
                type="daterange"
                style="width: 100%"
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                :default-time="['00:00:00', '23:59:59']"
              >
              </el-date-picker>
            </el-form-item>
          </el-col>
        </template>

        <el-col :sm="24" :md="12" :lg="8">
          <el-form-item>
            <el-button type="primary" @click="onSearch">查询</el-button>
            <el-button @click="onResetFilter">重置</el-button>
            <el-button type="text" @click="showMoreFilter = !showMoreFilter">
              更多
              <i
                :class="
                  showMoreFilter ? 'el-icon-arrow-up' : 'el-icon-arrow-down'
                "
              ></i>
            </el-button>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
  </section>
</template>

<script>
import BasicSelect from "@/components/BasicSelect";
import { GetTableFormatParams } from "@/utils/table";
// import { GetClientSourcesAll } from "@/views/client/clientSources/api";
import { format } from "date-fns";
import { onClearFilter, GetDepartmentIdsBySelf } from "@/utils/common";
export default {
  components: {
    BasicSelect,
  },
  data() {
    return {
      showMoreFilter: false,
      tableFilters: {
        yearMonth: "",
        examineeId: [],
        departmentIds: [],
        createdAt: [],
      },
      propObj: {
        ownerId: "examinee.id",
        departmentIds: "department.id",
      },
    };
  },
  created() {
    this.checkCurrentMonth();
  },
  computed: {
    useInfo() {
      return this.$store.getters.userInfo;
    },
    _ownerOptions() {
      return this.$store.getters.ownerOptions;
    },
    _departmnetTreeOptions() {
      return this.$store.getters.departmnetTreeOptions;
    },
  },
  methods: {
    onSearch() {
      let result = GetTableFormatParams(this.propObj, this.tableFilters);
      this.$emit("change", result);
      this.$emit("confirm", result);
    },
    onResetFilter() {
      this.tableFilters = onClearFilter(this.tableFilters);
      let result = GetTableFormatParams(this.propObj, this.tableFilters);
      this.$store.dispatch("basicOption/initOwnerOption");
      this.$store.dispatch("basicOption/initDepartmentTreeOption");
      this.$emit("change", result);
      this.$emit("clear", result);
    },
    checkCurrentMonth() {
      this.tableFilters.yearMonth = format(new Date(), "yyyy-MM");
      this.onSearch();
    },
    onCheckSelf() {
      if (this.useInfo !== {}) {
        this.tableFilters.examineeId = [this.useInfo.id];
      }
      this.onSearch();
    },
    onCheckSelfDepartment() {
      GetDepartmentIdsBySelf()
        .then((data) => {
          this.tableFilters.departmentIds = data;
        })
        .catch((data) => {
          this.tableFilters.departmentIds = data;
        })
        .finally(() => {
          this.onSearch();
        });
    },
    handleDepartmentChange() {
      if (this.tableFilters.departmentIds.length <= 0) {
        this.onSearch();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.filter-wrap {
  box-sizing: border-box;
  margin: 15px;
  margin-bottom: 0;
  padding: 22px;
  padding-bottom: 0;
  background-color: #fff;
  ::v-deep {
    .el-form-item__content {
      overflow: hidden;
    }
  }
}
</style>