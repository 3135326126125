<template>
  <section class="base-page">
    <PageHeader class="base-page-header base-shadow"> </PageHeader>
    <keep-alive><FilterSection @change="onFilterChange" /></keep-alive>
    <basic-table
      hiddenClear
      :loading="tableLoading"
      :tableColumns="tableColumns"
      :tableData="tableData"
      :page="page"
      :size="size"
      :total="total"
      :orderDirection="orderDirection"
      :orderProp="orderProp"
      @onSortChange="onTableSortChange"
      @onSizeChange="handleSizeChange"
      @onPageChange="handlePageChange"
      @onRefresh="onRefresh"
    >
      <template v-for="column in tableColumns">
        <el-table-column
          v-if="column.prop !== 'controlColumn' && column.showColumn"
          v-bind="column"
          :show-overflow-tooltip="true"
          :key="column.prop"
        >
          <template slot-scope="scope">
            <span v-if="column.prop === 'createdAt'">{{
              scope.row.createdAt | date
            }}</span>
            <span v-else-if="column.prop === 'signRate'"
              >{{ (scope.row.signRate * 100).toFixed(3) }} %</span
            >
            <span v-else>{{ scope.row[column.prop] }}</span>
          </template>
        </el-table-column>
      </template>
    </basic-table>
  </section>
</template>

<script>
import BasicTable from "@/components/BasicTable";
import FilterSection from "./components/FilterSection";
import { GetKpiList } from "@/views/kpi/api";
import { getOrderType } from "@/utils/common";
import PageHeader from "@/components/PageHeader";
import { ReadLocalColumn } from "@/utils/table";
import { format } from "date-fns";
import config from "@/config";
export default {
  components: { FilterSection, BasicTable, PageHeader },
  data() {
    return {
      tableLoading: false,
      tableColumns: [],
      page: 0,
      size: config.TABLE_SIZE,
      total: 0,
      tableData: [],
      orderProp: "createdAt",
      orderDirection: "descending",
      tableFilters: {
        yearMonth: format(new Date(), "yyyy-MM"),
      },
      orderPropObj: {},
    };
  },
  created() {
    this.buildTableColumns();
    this.onRefresh();
  },
  methods: {
    buildTableColumns(columns = []) {
      if (columns.length > 0) {
        this.tableColumns = columns;
      } else {
        let columns = [
          {
            prop: "departmentName",
            label: "部门",
            showColumn: true,
            minWidth: "120px",
            sortable: "custom",
          },
          {
            prop: "examineeName",
            label: "负责人",
            showColumn: true,
            minWidth: "120px",
            sortable: "custom",
          },

          {
            prop: "requestSignCount",
            label: "目标签约数量",
            showColumn: true,
            minWidth: "150px",
            sortable: "custom",
          },
          {
            prop: "actualSignCount",
            label: "实际签约数量",
            showColumn: true,
            minWidth: "150px",
            sortable: false,
          },
          {
            prop: "signRate",
            label: "完成率",
            width: 120,
            align: "center",
            showColumn: true,
            sortable: false,
          },
          {
            prop: "yearMonth",
            label: "年月",
            width: 120,
            align: "center",
            showColumn: true,
            sortable: "custom",
          },
          {
            prop: "createdAt",
            label: "创建时间",
            showColumn: true,
            width: 130,
            align: "center",
            sortable: "custom",
          },
        ];
        this.tableColumns = ReadLocalColumn(this.$route, columns);
      }
    },
    handleSizeChange(val) {
      this.page = 0;
      this.size = val;
      this.getTableData();
    },
    handlePageChange(val) {
      this.page = val - 1;
      this.getTableData();
    },
    getTableOrderParams() {
      let orderPropName = "";
      if (this.orderProp !== "" && this.orderDirection !== null) {
        orderPropName = Object.prototype.hasOwnProperty.call(
          this.orderPropObj,
          this.orderProp
        )
          ? this.orderPropObj[this.orderProp]
          : this.orderProp;
        return `${orderPropName},${getOrderType(this.orderDirection)}`;
      } else {
        return null;
      }
    },
    filterParams() {
      return { ...this.tableFilters };
    },
    getTableData() {
      this.tableLoading = true;
      GetKpiList({
        page: this.page,
        size: this.size,
        sort: this.getTableOrderParams(),
        ...this.filterParams(),
      })
        .then((res) => {
          let { content, totalElements } = res.data;
          this.tableData = content;
          this.total = totalElements;
          // 加载状态结束
          this.tableLoading = false;
        })
        .catch(() => {
          this.tableLoading = false;
        });
    },
    onRefresh() {
      this.page = 0;
      this.getTableData();
    },
    onTableSortChange({ prop, order }) {
      if (order === null) {
        this.orderProp = "createdAt";
        this.orderDirection = "descending";
      } else {
        this.orderProp = prop;
        this.orderDirection = order;
      }
      this.onRefresh();
    },
    onFilterChange(data) {
      this.tableFilters = data;
      this.onRefresh();
    },
  },
};
</script>

<style lang="scss" scoped>
.main-chart-container {
  box-sizing: border-box;
  margin: 20px;
  padding: 10px;
  background-color: #fff;
}
</style>